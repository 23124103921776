import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { first } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { ApiService } from '@api/api.service';
import { SettingsService } from '@api/settings.service';
import { AuthenticationService } from '@shared/authentication/authentication.service';
import { UserModel } from '@shared/authentication/interfaces';
import { BaseObject } from '@shared/base/base-object';
import { ExternalResourcesService } from '@shared/services/external-resources.service';
import { UserState } from '@shared/states/user.state';
import { BreakpointEnum, BreakpointTypeEnum } from '@shared/types/screen-breakpoints';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends BaseObject implements OnInit, AfterViewInit {
  constructor(
    private breakpointObserver: BreakpointObserver,
    private settingsService: SettingsService,
    private apiService: ApiService,
    private userState: UserState,
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private externalResourcesService: ExternalResourcesService,
  ) {
    super();
    this.titleService.setTitle('UGOL');

    // TODO вернуть когда решим проблему с сохранением куки при закрытии браузера
    // this.settingsService.getConfiguratorVersion().subscribe((data) => {
    //   if (!data.match) {
    //     console.log('NO MATCH')
    //     if (!data.anonymous_user) {
    //       this.apiService
    //         .resetOrder()
    //         .pipe(switchMap(() => this.authService.logout()))
    //         .subscribe(() => location.replace('configurator/order-create-flow'));
    //     } else {
    //       this.authService
    //         .logout()
    //         .subscribe(() => location.replace('configurator/order-create-flow'));
    //     }
    //   }
    // });

    this.userState.userIsLogin$
      .pipe(
        filter((userIsLogin) => userIsLogin),
        first(),
        switchMap(() => this.apiService.getProfile()),
      )
      .subscribe((user) => this.userState.user$.next(user as unknown as UserModel));
  }

  public ngOnInit(): void {
    const appTitle = this.titleService.getTitle();

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        switchMap(() => this.activatedRoute.firstChild.data),
      )
      .subscribe((data: Data) => this.titleService.setTitle(data['title'] || appTitle));

    this.breakpointObserver
      .observe([
        BreakpointEnum.XXSmall,
        BreakpointEnum.XSmall,
        BreakpointEnum.Small,
        BreakpointEnum.Medium,
        BreakpointEnum.Large,
        BreakpointEnum.XLarge,
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        const breakpoints = result.breakpoints;

        for (const prop in breakpoints) {
          if (breakpoints[prop]) {
            const indexOfBreakpoint = Object.values(BreakpointEnum).indexOf(
              prop as unknown as BreakpointEnum,
            );
            const key = Object.keys(BreakpointEnum)[indexOfBreakpoint];

            this.settingsService.screenBreakpoint$.next(key as unknown as BreakpointTypeEnum);
          }
        }
      });
  }

  public ngAfterViewInit(): void {
    this.externalResourcesService.loadExternalResources('root').pipe(first()).subscribe();
  }
}
