import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  CanLoad,
  Route,
  UrlSegment,
  CanActivateChild,
  Router,
} from '@angular/router';
import { first, Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '@shared/authentication/authentication.service';
import { UserState } from '@shared/states/user.state';
import { UserModel } from '@shared/authentication/interfaces';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {
  private isValidToken$: Observable<boolean>;

  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private userState: UserState,
  ) {
    this.isValidToken$ = this.auth.authCheck().pipe(
      switchMap((valid) => {
        if (valid) {
          this.userState.userIsLogin$.next(true);
          return of(true);
        }

        return this.auth.anonymousLogin().pipe(
          first(),
          map((result) => {
            this.authSubscribeLogic(result);

            return !!result;
          }),
        );
      }),
    );
  }

  private authSubscribeLogic(result: UserModel): void {
    if (result) {
      this.userState.user$.next(result);
      this.userState.userIsLogin$.next(true);
      location.replace('configurator');
    }
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.isValidToken$;
  }

  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.isValidToken$;
  }

  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.isValidToken$;
  }
}
